body {
  background-color: #fff;
}

.flex-grow {
  flex: 1 0 0;
}

button {
  cursor: pointer;
  background: #d2dae2;
  transition: background .3s ease-in-out;
  color: #485460;
  border: none;
}

  button:hover {
    background-color: #4bcffa;
  }
